import { useAxiosInstance } from '../../../../utils/axios';

const TopbarService = () => {
  const { axiosInstanceWithoutSession, axiosInstance } = useAxiosInstance();

  const changePswd = async (
    email: string,
    oldPswd: string,
    newPswd: string,
  ) => {
    return await axiosInstanceWithoutSession().put(`/account/password`, {
      email,
      old: oldPswd,
      new: newPswd,
    });
  };

  const changeNeighborhood = async (id: number) => {
    return axiosInstance().get(`admin/choose-neighborhood/${id}`);
  };

  return { changePswd, changeNeighborhood };
};

export { TopbarService };
